"use client";

import { createContext, useContext, useEffect, useState } from "react";
import { AgencyProfile } from "@/types/agencies";
import { api, apiLive } from "@/utils/common/api";
import React from "react";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import {
  SupportedCountries,
  SupportedLanguages,
} from "@/enums/internationalization";
import posthog from "posthog-js";
import { BRANDS } from "@/enums/brands";

type ProfileProps = {
  country: SupportedCountries;
  countryCode: string;
  language: string;
  profile: AgencyProfile;
  allowedCountries?: SupportedCountries[];
  setProfile: (profile: AgencyProfile) => Promise<void>;
};

const getDefaultProfile = () => {
  return {
    locale: "en-US",
    country: SupportedCountries.UnitedStates,
    languages: [SupportedLanguages.English],
    isDefault: true,
    brand: BRANDS.GAIL,
  };
};

export const ProfileContext = createContext<ProfileProps>({
  country: SupportedCountries.UnitedStates,
  countryCode: "+1",
  language: "en",
  profile: getDefaultProfile(),
  allowedCountries: [SupportedCountries.UnitedStates],
  setProfile: async () => {},
});

export const useProfile = () => useContext(ProfileContext)!;

const useProfileFunction = ({
  setAppLanguage,
}: {
  setAppLanguage: (language: string) => void;
}): ProfileProps => {
  const { getToken } = useAuthContext();

  const [profile, setProfile] = useState<AgencyProfile>(getDefaultProfile());
  const [countryCode, setCountryCode] = useState<string>("+1");
  const [language, setLanguage] = useState<string>("en");
  const [allowedCountries, setAllowedCountries] = useState<
    SupportedCountries[]
  >([SupportedCountries.UnitedStates]);

  const setUiFields = (profile: AgencyProfile) => {
    if (
      !Object.values(SupportedLanguages).find(
        (l) => l == profile.locale.split("-")[0]
      )
    ) {
      setLanguage("en");
    } else {
      setLanguage(profile.locale.split("-")[0]);

      if (!profile.isDefault) {
        setAppLanguage(profile.locale.split("-")[0]);
      }
    }

    if (!Object.values(SupportedCountries).find((l) => l == profile.country)) {
      setCountryCode("+1");
    } else {
      setCountryCode(
        `+${
          profile.country == "US" ? "1" : profile.country == "MX" ? "52" : ""
        }`
      );
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("profile")) {
      const cachedProfile = window.localStorage.getItem("profile");

      if (cachedProfile) {
        const newProfile = JSON.parse(cachedProfile) as AgencyProfile;

        setProfile(newProfile);
      }
    } else {
      let locale =
        navigator.languages && navigator.languages.length > 0
          ? navigator.languages[0]
          : "en-US";

      let country =
        locale.split("-").length > 1
          ? (locale.split("-")[1] as SupportedCountries)
          : SupportedCountries.UnitedStates;

      let language =
        locale.split("-").length > 1
          ? (locale.split("-")[0] as SupportedLanguages)
          : SupportedLanguages.English;

      if (
        !Object.values(SupportedLanguages).find(
          (l) => l == locale.split("-")[0]
        )
      ) {
        locale = "en-US";
      }

      if (!Object.values(SupportedCountries).find((l) => l == country)) {
        country = SupportedCountries.UnitedStates;
      }

      const brand = (window.localStorage.getItem("brand") ??
        BRANDS.GAIL) as BRANDS;

      setProfile({
        locale,
        country,
        languages: [language],
        isDefault: true,
        brand: brand,
      });

      setUiFields({
        locale,
        country,
        languages: [language],
        isDefault: true,
        brand: brand,
      });
    }

    const fetchProfile = async () => {
      const profile = await apiLive<AgencyProfile>(
        getToken,
        "/api/settings/agency/profile"
      );

      if (profile) {
        setProfile(profile);
        setUiFields(profile);
        window.localStorage.setItem("profile", JSON.stringify(profile));
        const brand = (window.localStorage.getItem("brand") ??
          BRANDS.GAIL) as BRANDS;

        if (
          profile.brand != brand &&
          process.env.NEXT_PUBLIC_ENVIRONMENT !== "development"
        ) {
          window.localStorage.setItem("brand", profile.brand);
          window.location.reload();
        }
      }
    };

    posthog.onFeatureFlags(() => {
      if (posthog.isFeatureEnabled("ui-allowed-countries")) {
        const countries = posthog.getFeatureFlagPayload(
          "ui-allowed-countries"
        ) as SupportedCountries[] | undefined;

        if (!countries || countries.length == 0) {
          setAllowedCountries(Object.values(SupportedCountries));
        } else {
          setAllowedCountries(countries);
        }
      }
    });

    fetchProfile();
  }, []);

  const handleProfile = async (updatedProfile: AgencyProfile) => {
    const newProfile = await api<AgencyProfile, AgencyProfile>(
      getToken,
      "/api/settings/agency/profile",
      "PUT",
      updatedProfile
    );

    if (newProfile) {
      setProfile(newProfile);
      setUiFields(newProfile);
      window.localStorage.setItem("profile", JSON.stringify(newProfile));
    }
  };

  return {
    country: profile.country,
    countryCode,
    language,
    profile,
    allowedCountries,
    setProfile: handleProfile,
  };
};

export const ProfileContextProvider = ({
  children,
  setLanguage,
}: {
  children: React.ReactNode;
  setLanguage: (language: string) => void;
}) => {
  const profileFunction = useProfileFunction({ setAppLanguage: setLanguage });

  return (
    <ProfileContext.Provider value={profileFunction}>
      {children}
    </ProfileContext.Provider>
  );
};
